<template>
	<div class="loading">
		<h1>Loading...</h1>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss" scoped>
.loading {
	width: 100vw;
	height: 100vh;
	vertical-align: middle;
	padding-top: 40vh;
}
</style>