<template>
  <div id="cardHolder">
    <div
      v-for="project in projects"
      :key="project.id"
      :id="project.id"
      class="projectCard"
    >
      <div class="frame">
        <div class="projLogo">
          <svg :data-src="project.icon" class="projectIcon" />
        </div>
        <div class="date">{{ project.DATE }}</div>
        <div class="name">{{ project.PROJECTNAME }}</div>
        <div class="location">{{ project.LOCATION }}</div>
        <div class="area"><span v-if="project.Area">
          {{ project.AREA }} <span v-if="project.AREA != 'Varies'">sf</span></span>
        </div>
        <div class="description" v-html="project.DESCRIPTION"></div>
        <div v-if="project.PHOTOGRAPHY != 'UNKNOWN'" class="photocredit">
            <span v-if="project.PHOTOGRAPHY"> Images by {{ project.PHOTOGRAPHY }}</span>
        </div>
        <swiper
          class="images mySwiper"
          @swiper="onPhotoSwiper"
          observer
          :slides-per-view="1"
          :navigation="project.PHOTOS.length > 1"
          :space-between="20"
        >
          <swiper-slide
            zoom
            v-for="photo in project.PHOTOS"
            :key="photo"
            :ref="project.id"
          >
            <img :src="photo.pathLong" /> </swiper-slide
        ></swiper>
          <div class="link">
            <span v-if="project.LINK">
              <a :href="project.LINK"
                >See more about {{ project.PROJECTNAME }} here.
                <svg width="15px" height="15px" viewBox="0 0 24 24">
                  <g
                    id="external_link"
                    class="icon_svg-stroke"
                    stroke="#666"
                    stroke-width="1.5"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline
                      points="17 13.5 17 19.5 5 19.5 5 7.5 11 7.5"
                    ></polyline>
                    <path d="M14,4.5 L20,4.5 L20,10.5 M20,4.5 L11,13.5"></path>
                  </g></svg></a
            ></span>
          </div>
      </div>
    </div>
    <!-- </swiper>
  </div> -->
  <div class="projectCard">
    <a href="http://www.johnstonarchitects.com"> <img :src="jalogoimg" style="width:50vw"></a>
    <p class="cc">Copyright {{copyright}}</p>
    </div>
  </div>
</template>

<script>
import jalogoimg from "@/assets/JA-Logo-Round-Black-Transparent.png";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.scss";
// import anime from "animejs";

SwiperCore.use([Navigation]);

export default {
  props: ["projectsData"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      projects: this.projectsData,
      swiper: null,
      jalogoimg: jalogoimg,
      copyright: new Date().getFullYear(),
      photoSwiper: null,
    };
  },
  methods: {
    // fadeDesc(that) {
    //   anime({
    //     targets: that.$refs.display.$refs.swiperContainer,
    //     opacity: 1,
    //   });
    // },
    onPhotoSwiper(swiper) {
      this.photoSwiper = swiper;
    },
  },
  mounted() {
    console.log("PROJECTS", this.projects);
  },
};
</script>

<style lang="scss">
$activeFill: rgb(42, 42, 53);
$inactiveFill: rgb(205, 205, 212);
.projectIcon path, .projectIcon rect {
  fill: $activeFill;
}
</style>
<style lang="scss" scoped>
$activeFill: rgb(42, 42, 53);
$inactiveFill: rgb(205, 205, 212);
#MDisplay {
  background-color: $inactiveFill;
}
.projectCard {
  background-color: white;
  width: 100vw;
  padding-top: 20px;
  filter: drop-shadow(0 0px 6px rgb(175, 175, 175));
  margin-bottom: 80px;
}

.frame {
  // padding-top: 110px;
  width: 100%;
  text-align: left;
  // height: 59vh;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 2em auto 1.2em 1.2em auto 1em auto;
  grid-template-areas:
    " projLogo date"
    " projLogo name"
    " projLogo location "
    " projLogo area "
    " image image"
    " photography photography"
    " description description"
    " link link ";
}
.projLogo {
  grid-area: projLogo;
  align-self: center;
  justify-items: center;
  text-align: center;
  width: 40vw;
}
.projectIcon {
  min-width: 30vw;
  margin: auto;
  max-height: 130px;
  height: auto;
}
.name {
  font-size: 1.8em;
  line-height: 1.2em;
  font-weight: 599;
  grid-area: name;
  // padding-bottom: 15px
}
.date {
  color: #999;
  grid-area: date;
  font-weight: 599;
  margin-bottom: 15px;
  font-size: 1.6em;
}
.area {
  height: 0px;
  grid-area: area;
}
.location {
  grid-area: location;
  // margin-top: -5px;
}
.description {
  grid-area: description;
  margin-top: 40px;
  padding: 0 20px;
  // margin-bottom: 40px;
  /* overflow: auto; */
}
.link {
  grid-area: link;
  margin: auto;
  padding-bottom: 20px;
}
.photocredit {
  grid-area: photography;
  padding: 0 20px;
  margin-top: 10px;
  font-style: italic;
  font-size: 0.8em;
}
.images {
  // margin-left: 49px;
  margin-top: 20px;
  height: 30vh;
  width: 100%;
  grid-area: image;
}
.swiper-wrapper {
  height: auto !important;
}
.swiper-slide {
  text-align: center;
}
.swiper-slide img {
  margin: auto;
  height: 30vh;
}
.photoSlide {
  width: 59vw;
  height: 50vh;
}
.photoWrapper {
  // height: 60vh;
  // width: 50vw;
  display: flex;
  align-content: center;
  img {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.cc {
  padding-bottom: 100px;
  color: #888;
}
</style>
