<template>
  <MDisplay ref="display" id="MDisplay" :projects-data="projects" />
  <div class="footer">
    <img
      id="jaLogo"
      :src="jalogoimg"
      onclick="window.location.href='http://www.johnstonarchitects.com'"
    />
  </div>

  <div ref="logo" id="logo">
    <img id="thirtylogo" ref="thirtylogo" :src="thirtylogo" alt="30" />

    <div id="JAname" ref="JAname">
      <svg :data-src="JAsubtitle" />
    </div>
    <div id="tapanywhere">Tap Anywhere</div>
  </div>
</template>

<script>
import inactive from "@/assets/icons/INACTIVE.svg";
import jalogoimg from "@/assets/JA-Logo-Round-Black-Transparent.png";
import thirtylogo from "@/assets/logoPNG.png";
// import loadingComponent from "./LoadingComponent.vue";
import JAsubtitle from "@/assets/JA_web_subtitle.svg";
import MDisplay from "./MDisplay.vue";
import anime from "animejs";
import "external-svg-loader";

export default {
  props: ["projectsData"],
  components: {
    MDisplay,
    // loadingComponent,
  },
  // ! DATA()
  data() {
    return {
      // ? objects
      inactive: inactive,
      iconRefs: [],
      projects: this.projectsData,
      JAsubtitle: JAsubtitle,
      dragBar: this.$refs.dragBar,
      inactiveLogo: null,
      logoScale: 0.85,
      jalogoimg: jalogoimg,
      thirtylogo: thirtylogo,
      inactiveLogoWidth: 535,
      logoPos: { left: "", top: "" },

      // ? TOGGLE STATES
      loading: true,
      timeline: 0,
    };
  },

  // ! METHODS
  methods: {
    getProjObj(id) {
      // * Returns the project object based on its id string (ie "timber_cove")
      for (let i = 0; i < this.projects.length; i++) {
        const proj = this.projects[i];
        for (const key in proj) {
          if (proj[key].indexOf(id) != -1) {
            return proj;
          }
        }
      }
    },

    toTimeline() {
      let that = this;
      if (!this.timeline) {
        that.timeline = !that.timeline;
        console.log("TO TIMELINE");
        anime({
          targets: "#logo",
          translateY: -900,
          duration: 3000,
          complete: () => {
            document.getElementById("logo").remove();
          },
        });
        anime({
          targets: "#MDisplay",
          top: 0,
          easing: "linear",
          duration: 800,
        });
      }
    },
    removeListeners(old) {
      console.log(old.target);
      const new_node = old.target.cloneNode(true);
      old.target.parentNode.replaceChild(new_node, old.target);
    },
  },

  //! UPDATED()
  updated() {},

  // ! MOUNTED()
  mounted() {
    // document.getElementById("logo").remove();
    this.$refs.thirtylogo.style = "margin-top: 20vh"
    this.$nextTick(function () {
      console.log("UPDATED");
      this.loading = false;
      anime({
        targets: ["#logo"],
        opacity: 1,
        easing: "linear",
        duration: 1000,
      });
      anime({
        targets: "#JAname",
        opacity: 1,
        easing: "easeInCubic",
        duration: 1000,
        complete: () => {
          setTimeout(() => {
            anime({
              targets: "#tapanywhere",
              opacity: 1,
              easing: "easeInCubic",
              duration: 2000,
            });
          }, 1000);
        },
      });
    });

    document.getElementById("logo").addEventListener("click", () => {
      this.toTimeline();
      // this.removeListeners(e);
    });
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
$inactiveFill: rgb(165, 165, 175);
$activeFill: rgb(42, 42, 53);
$windowWidth: 100vw;

#logo {
  width: 100vw;
}
#logo img {
  width: 80vw;
}
#jaLogo {
  // display: none;
  // position: fixed;
  height: 100px;
  top: 10px;
  left: 50px;
  z-index: 1000;
  // visibility: hidden;
  // opacity: 0;
  &:hover {
    cursor: pointer;
  }
}
#MDisplay {
  position: relative;
  top: 100vh;
}

#JAname {
  // position: fixed;
  opacity: 0;
  margin-top: 10px;
}
#JAname svg {
  width: 80vw;
}
#tapanywhere {
  opacity: 0;
  margin-top: 50px;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(146, 146, 146);
  font-weight: 600;
}
#middleTick {
  position: fixed;
  left: 50vw;
  height: 20px;
  bottom: 0px;
  border: solid rgb(85, 83, 83) 1px;
  width: 0px;
  margin: auto;
  opacity: 0;
}
#welcome {
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: 500;
}
#timelineCont {
  // border-top: solid 2px rgb(42, 42, 53);
  // background-color: rgb(129, 135, 141);
  position: fixed;
  display: block;
  // visibility: hidden;
  opacity: 0;
  width: 100vw;
  bottom: 0;
  /* z-index: -1; */
}
#logo {
  top: 0vh;
  z-index: 1;
  position: absolute;
  height: 100%;
  // width: 100vw;
}
.icons {
  opacity: 0;
  // scale: 0.6;
  /* height: 30px; */
  position: fixed;
  display: block;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.icons svg {
  height: 30px;
}
.dates {
  position: relative;
  font-size: 0.4em;
  display: block;
  opacity: 0;
  /* visibility: hidden; */
  left: 0px;
  top: -4px;
  z-index: 0;
}
.iconSVG {
  pointer-events: none;
}
</style>
