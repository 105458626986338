<template>
  <div v-if="!isMobile()">
    <Logo v-cloak :projects-data="projectsData" />
  </div>
  <div v-else>
    <MLogo :projects-data="projectsData" />
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import MLogo from "@/components/MLogo.vue";
import Papa from "papaparse";
import anime from "animejs";
import raw_project_data from "@/assets/30th_project_descriptions.csv";
import initPos from "@/assets/initPos.json";
export default {
  components: {
    Logo: Logo,
    MLogo: MLogo,
  },
  name: "Main",
  props: {
    msg: String,
  },
  data() {
    return {
      projectsData: {},
    };
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    split_id(id) {
      return id.split("/")[2].split(".")[0];
    },
    importAll(ctx) {
      ctx.keys().forEach((key) => {
        let proj = key.split("/")[1];
        this.projectsData.forEach((obj) => {
          if (obj.id == proj) {
            // console.log("OBJECT ID", obj.id, "PROJECT ID", proj);
            if (!obj.PHOTOS) {
              obj.PHOTOS = [];
            }
            obj.PHOTOS.push({ pathLong: ctx(key), pathShort: key });
          }
        });
      });
    },
  },
  created() {
    let that = this;
    Papa.parse(raw_project_data, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        that.projectsData = results.data.sort(function (a, b) {
          return a.DATE - b.DATE;
        });
        console.log("PARSE RESULTS:", results.data);
      },
    });

    console.log(anime);

    for (const project in this.projectsData) {
      const proj = that.projectsData[project];
      const filename = proj.id + ".svg";
      proj.icon = require("@/assets/icons/" + filename);
      proj.initPos = initPos["initPos"][0][proj.id]
        .split(",")
        .map(Number)
        .map(Math.round);
      // proj.PHOTOS = proj.PHOTOS.split(";");
      // proj.PHOTOURL = proj.PHOTOS.map((x) => {
      //   return require("@/assets/photos/" + x);
      // });
    }
    this.importAll(
      require.context("../assets/photos/projects", true, /\.jpg$/)
    );
  },
  mounted() {
    // this.importAll(
    //   require.context("../assets/photos/projects", true, /\.jpg$/)
    // );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/style.css";
@import "../assets/style.scss";
[v-cloak] {
  display: none;
}
</style>
