<template>
  <div ref="swiperContainer" id="swiperContainer">
    <swiper
      class="projectContainer mySwiper"
      @swiper="onSwiper"
      :speed="800"
      :allowTouchMove="false"
      :slides-per-view="1"
      :space-between="0"
    >
      <swiper-slide
        v-for="project in projects"
        :key="project.id"
        :id="project.id"
        class="projectSlide"
      >
        <div class="frame">
          <div class="name">{{ project.PROJECTNAME }}</div>
          <div class="date">{{ project.DATE }}</div>
          <div class="location">{{ project.LOCATION }}</div>
          <div class="area">
            <span v-if="project.AREA">
              {{ project.AREA }} <span v-if="project.AREA != 'Varies'">sf</span>
            </span>
          </div>
          <div class="description" v-html="project.DESCRIPTION"></div>
          <div class="link">
            <span v-if="project.LINK">
              <a :href="project.LINK"
                >See more about {{ project.PROJECTNAME }} here.
                <svg width="15px" height="15px" viewBox="0 0 24 24">
                  <g
                    id="external_link"
                    class="icon_svg-stroke"
                    stroke="#666"
                    stroke-width="1.5"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline
                      points="17 13.5 17 19.5 5 19.5 5 7.5 11 7.5"
                    ></polyline>
                    <path d="M14,4.5 L20,4.5 L20,10.5 M20,4.5 L11,13.5"></path>
                  </g></svg></a
            ></span>
          </div>
          <div v-if="project.PHOTOGRAPHY != 'UNKNOWN'" class="photocredit">
            <span v-if="project.PHOTOGRAPHY"> Images by {{ project.PHOTOGRAPHY }}</span>
          </div>
          <swiper
            class="images mySwiper"
            @swiper="onPhotoSwiper"
            observer
            :slides-per-view="1"
            :navigation="project.PHOTOS.length > 1"
            :space-between="20"
          >
            <swiper-slide
              v-for="photo in project.PHOTOS"
              :key="photo"
              :ref="project.id"
            >
              <div class="photoWrapper">
                <img :src="photo.pathLong" />
              </div> </swiper-slide
          ></swiper>
          <!-- </div> -->
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.scss";
import anime from "animejs";

SwiperCore.use([Navigation]);

export default {
  props: ["projectsData"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      projects: this.projectsData,
      swiper: null,
      photoSwiper: null,
    };
  },
  methods: {
    fadeDesc(that) {
      // console.log("FADE DESC", that);
      anime({
        targets: that.$refs.display.$refs.swiperContainer,
        // autoplay: false,
        opacity: 1,
        // translateX: "400px",
      });
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onPhotoSwiper(swiper) {
      this.photoSwiper = swiper;
    },
  },
  mounted() {
    this.$refs.swiperContainer.style.opacity = 0;
    console.log("PROJECTS", this.projects);
    // this.$nextTick(() => {
    //   // Make sure you are doing it in next tick, otherwise it won't work
    //   setTimeout(() => {
    //     console.log("NEXT TICK");
    //     console.log(this.photoSwiper);
    //     this.photoSwiper.navigation.update();
    //     console.log(
    //       this.$refs.timber_cove.swiperRef
    //     );
    //     this.$refs.timber_cove.swiperRef._value.navigation.update();
    //   }, 1000);
    // });
  },
};
</script>

<style lang="scss" scoped>
.projectContainer {
  width: 100vw;
  /* height: 79vh; */
  /* z-index: 99; */
  /* opacity: -1;
}
.photoContainer{
  /* width: 99%; */
  /* height: 99%; */
}

.frame {
  padding-top: 110px;
  width: 90vw;
  text-align: left;
  height: 59vh;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  grid-template-rows: 0fr 20px 20px 20px 20fr 2fr 1fr;
  grid-template-areas:
    " name image"
    " date image"
    " location image"
    " area image"
    " description image"
    " link photography";
}
.name {
  padding-top: 10vh;
  font-size: 1.8em;
  font-weight: 599;
  grid-area: name;
}
.link {
  grid-area: link;
  padding-top: 10px;
}
.link a {
  text-decoration: none;
}
.date {
  margin: 0px;
  margin-top: -5px;
  grid-area: date;
}
.area {
  height: 0px;
  grid-area: area;
  padding: 0px;
  margin: 0px;
  margin-top: -5px;
}
.location {
  grid-area: location;
  padding: 0px;
  margin: 0px;
  margin-top: -5px;
}
.description {
  grid-area: description;
  margin-top: 20px;
  overflow-y: auto;
  /* overflow: auto; */
}
.photocredit {
  grid-area: photography;
  text-align: right;
  margin: 0px;
  margin-top: 10px;
  font-size: 0.8em;
}
.images {
  margin-left: 49px;
  width: 100%;
  grid-area: image;
}
// .photoSlide {
//   /* width: 59vw;
//   height: 50vh; */
// }
.photoWrapper {
  height: 60vh;
  // width: 50vw;
  display: flex;
  align-content: center;
  img {
    margin: auto;
    max-width: 100%;
    max-height: 80%;
  }
}
</style>
