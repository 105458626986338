<template>
  <!-- <div id="counter" >{{ draggingBar }}</div> -->
  <loading-component v-show="loading" ref="loadingComponent" />
  <div id="logoWrapper" v-show="!loading">
    <img
      id="jaLogo"
      :src="jalogoimg"
      onclick="window.location.href='http://www.johnstonarchitects.com'"
    />
    <svg
      id="thirtyLogo"
      :data-src="thirtyLogo"
      onclick="window.location.reload()"
    />
  </div>
  <Display ref="display" :projects-data="projects" />

  <div id="logo">
    <div
      class="icons iconActive unselectable"
      v-for="project in projects"
      :id="project.id"
      :key="project.id"
      :ref="setIconRef"
      draggable="false"
    >
      <!-- @click="goToIndex({ i: projects.indexOf(project) })" -->
      <div class="iconWrapper">
        <svg
          class="iconSVG"
          :data-src="project.icon"
          draggable="false"
          ondragstart="return false;"
        />
      </div>
      <div ref="date" :class="{ show: dateVis }" class="dates">
        {{ project.DATE }}
      </div>
    </div>
    <div id="inactive" ref="inactiveLogo">
      <svg :data-src="inactive" />
      <div id="JAname" ref="JAname">
        <svg :data-src="JAsubtitle" />
      </div>
    </div>
  </div>
  <div id="timelineCont" ref="timelineCont">
    <!-- <div id="welcome">Click Here to See Our History</div> -->
    <div id="middleTick"></div>
    <!-- <div :ref="dragBar" id="dragBar"></div> -->
  </div>
</template>

<script>
import inactive from "@/assets/icons/INACTIVE.svg";
import loadingComponent from "./LoadingComponent.vue";
import JAsubtitle from "@/assets/JA_web_subtitle.svg";
import thirtyLogo from "@/assets/icons/COMPLETE.svg";
// import jalogoimg from "@/assets/JA-Logo-Round-Black-Transparent.png";
import jalogoimg from "@/assets/JA_Circle-Black_small.png";
import Display from "./Display.vue";
import anime from "animejs";
import "external-svg-loader";

export default {
  props: ["projectsData"],
  components: {
    Display,
    loadingComponent,
  },
  // ! DATA()
  data() {
    return {
      // ? objects
      inactive: inactive,
      iconRefs: [],
      projects: this.projectsData,
      thirtyLogo: thirtyLogo,
      JAsubtitle: JAsubtitle,
      dragBar: this.$refs.dragBar,
      inactiveLogo: null,
      logoPos: { left: "", top: "" },
      iconPos: [],
      inactiveLogoWidth: 535,
      jalogoimg: jalogoimg,

      // ? VARIABLES
      tlHeight: 70,
      tlSpacing: 200,
      iconYOffset: 30,
      iconScale: 2.5,
      SVGLoad: 0,

      // ? TOGGLE STATES
      loading: true,
      timeline: 0,
      draggingBar: 0,
      dateVis: 0,

      // ? Index Tracker
      currentIndex: 0,
      lastIndex: 0,

      // ? Timeline Variables
      timelineMax: null,
      tlPos: 0,
      prevPos: 0,
    };
  },

  // ! METHODS
  methods: {
    incrementSVGLoad() {
      this.SVGLoad += 1;
      console.log("INCREMENTING");
    },
    setIconRef(el) {
      // * Creates list of icon from div refs in v-FOR loop
      if (el && this.iconRefs.indexOf(el) == -1) this.iconRefs.push(el);
      this.$nextTick(this.setInitPos(el));
    },

    map_range(value, low1, high1, low2, high2) {
      return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
    },

    closest(array, goal) {
      return array.reduce(function (prev, curr) {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      });
    },

    getProjObj(id) {
      // * Returns the project object based on its id string (ie "timber_cove")
      for (let i = 0; i < this.projects.length; i++) {
        const proj = this.projects[i];
        for (const key in proj) {
          if (proj[key].indexOf(id) != -1) {
            return proj;
          }
        }
      }
    },

    toggleTimeline() {
      this.timeline = !this.timeline;
      this.offsetSVG();
      const Logo = document.getElementById("logoWrapper").children;
      // Logo.style.visibility = "visible"
      anime({
        targets: Logo,
        opacity: 1,
        easing: "linear",
        duration: 1800,
        begin: (anim) => {
          anim.animatables.forEach((el) => {
            console.log(el);
            el.target.style.display = "block";
          });
        },
      });
    },

    toggleDraggingBar() {
      this.draggingBar = 1;
    },

    offsetSVG() {
      const iconSVGs = document.getElementsByClassName("iconSVG");
      console.log("ICON SVGS", iconSVGs);
      iconSVGs.forEach((element) => {
        console.log(element.getBBox());
        // element.style.top = 30 - element.getBBox().height + "px";
        element.style.position = "relative";
      });
    },

    getIconPos() {
      // * set this.iconPos with x positions of icons
      this.iconPos = [];
      for (const i in this.iconRefs) {
        const icon = this.iconRefs[i];
        this.iconPos.push(icon.getBoundingClientRect().left);
      }
    },

    setIconPos() {
      this.getIconPos();
      let els = this;
      anime({
        targets: els.iconRefs,
        left:
          "+=" +
          (window.innerWidth / 2 -
            els.iconPos[els.currentIndex] -
            els.iconRefs[els.currentIndex].offsetWidth / els.iconScale),
        translateY: function (el) {
          return -el.y - els.tlHeight + window.innerHeight - 10;
        },
      });
    },

    setLogoPos() {
      //* Sets position of the 'inactive' 30 icon
      this.logoPos["left"] = window.innerWidth / 2 - this.inactiveLogoWidth / 2;
      this.logoPos["top"] = window.innerHeight * 0.1;
      this.inactiveLogo.style.left = this.logoPos["left"] + "px";
      this.inactiveLogo.style.top = this.logoPos["top"] + "px";
    },

    setInitPos(el) {
      //* Sets the Icons to their initial Posiiton in the 30 logo
      if (el) {
        const pos = this.getProjObj(el.id).initPos;
        el.style.top = pos[1] + +this.logoPos["top"] + "px";
        el.style.left = pos[0] + +this.logoPos["left"] + "px";
      }
    },

    fadeLogo(dur) {
      let that = this;
      anime({
        targets: ["#inactive", "#JAname"],

        opacity: 0,
        easing: "linear",
        complete: function () {
          document.getElementById("JAname").visibility = "hidden";
          that.inactiveLogo.style.visibility = "hidden";
          that.$refs.display.fadeDesc(that);
        },
        duration: dur,
      });
    },

    toTimeline() {
      console.log("TO TIMELINE RUN");
      // * Function that controls transfer from 30 logo to timeline
      // document.getElementById("welcome").style.opacity = 0;
      document.documentElement.style.setProperty("--iconmargin", "auto");
      document.getElementById("middleTick").style.opacity = 1;
      // document.body.removeEventListener("mouseup", this.toTimeline);
      this.toggleTimeline();
      this.fadeLogo(1500);
      this.goToIndex({ i: 0, custEase: "easeOutQuad", dur: 1500 });
      anime({
        targets: [".dates", "#timelineCont"],
        opacity: 1,
        easing: "linear",
        duration: 1000,
      });
      // this.dateVis = !this.dateVis;
    },
    goToIndex({ i, custEase = "spring", dur = 800 }) {
      // this.draggingBar = 0;
      this.currentIndex = i;
      let that = this;
      if (this.timeline) {
        anime({
          targets: that.iconRefs,
          left: function (el, idx) {
            return (
              window.innerWidth / 2 +
              (idx - i) * that.tlSpacing -
              el.offsetWidth / 2
            );
          },
          translateY: function (el) {
            // console.log(el.offsetTop, that.tlHeight + window.innerHeight);
            return (
              -el.offsetTop -
              that.tlHeight +
              window.innerHeight -
              that.iconYOffset
            );
          },
          scale: that.iconScale,
          easing: custEase,
          duration: dur,
          update: () => {
            that.iconRefs.forEach((el) => {
              el.classList.remove("active");
              // el.classList.add("iconInactive");
            });
          },
          complete: () => {
            that.getIconPos();
            console.log(that.iconRefs[i]);
            that.iconRefs.forEach((el) => {
              el.classList.remove("active");
            });
            that.iconRefs[i].classList.add("active");
            // that.iconRefs[i].classList.remove("iconInactive");
          },
        });
        this.$refs.display.swiper.slideTo(i, 800);
      }
    },

    dragSlideIcons(e) {
      // * Moves thsei icons as you drag the timeline
      if (this.draggingBar) {
        this.tlPos = e.pageX;
        console.log("dragging bar ", this.draggingBar);
        // this.prevPos = this.tlPos
        const diff = this.prevPos - this.tlPos;
        // console.log(diff);
        let els = this.iconRefs;
        anime({
          targets: els,
          left: "+=" + -diff,
          easing: "linear",
          duration: 0,
        });
      }
    },

    dragScroll() {
      // * switches draggingBar toggle to allow dragSlideIcons to work
      // this.getIconPos();
      console.log("DRAGSCROLL");
      if (this.timeline) {
        this.draggingBar = 1;
      }
    },

    endDragScroll() {
      // * called on mouseup, ends dragging and calls function to position icons
      if (this.draggingBar) {
        this.getIconPos();
        this.goToIndex({
          i: this.iconPos.indexOf(
            this.closest(this.iconPos, window.innerWidth / 2)
          ),
          custEase: "linear",
          dur: 20,
        });
        this.$refs.display.swiper.slideTo(this.currentIndex, 100);
      }
      this.draggingBar = 0;
    },

    preventClick(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },
    removeListeners(old) {
      console.log(old);
      // const new_node = old.cloneNode(true);
      // old.parentNode.replaceChild(new_node, old);
    },
  },

  //! UPDATED()
  updated() {
    // this.$nextTick(function () {
    //   setTimeout(() => {
    //     this.loading = false;
    //     anime({
    //       targets: [".icons", "#inactive"],
    //       opacity: 1,
    //       easing: "linear",
    //       duration: 2000,
    //     });
    //     anime({
    //       targets: "#JAname",
    //       opacity: 1,
    //       easing: "easeInCubic",
    //       duration: 2000,
    //       complete: () => {},
    //     });
    //   }, 3000);
    // });
  },

  // ! MOUNTED()
  mounted() {
    this.inactiveLogo = this.$refs.inactiveLogo;
    this.setLogoPos();
    this.$nextTick(function () {
      setTimeout(() => {
        this.loading = false;
        anime({
          targets: [".icons", "#inactive"],
          opacity: 1,
          easing: "linear",
          duration: 2000,
        });
        anime({
          targets: "#JAname",
          opacity: 1,
          easing: "easeInCubic",
          duration: 2000,
          complete: () => {},
        });
      }, 3000);
    });

    //* Set positions for title text
    // this.$refs.JAname.style.top =
    //   this.logoPos["top"] + this.inactiveLogo.offsetHeight * 2 + "px";
    // this.$refs.JAname.style.left =
    //   this.logoPos["left"] +
    //   this.inactiveLogoWidth / 2 -
    //   this.$refs.JAname.offsetWidth / 2 +
    //   "px";

    //* timelineCont height
    this.$refs.timelineCont.style.height = this.tlHeight + "px";

    //* SET DRAG CLICK FUNCTIONS TO ICONS AND DRAGBAR
    for (let i = 0; i < this.iconRefs.length; i++) {
      const element = this.iconRefs[i];

      element.addEventListener("mousedown", (e) => {
        this.tlPos = e.pageX;
        element.addEventListener("mousemove", this.toggleDraggingBar);
      });

      element.addEventListener("mouseup", () => {
        if (this.draggingBar) {
          this.endDragScroll();
        } else {
          element.removeEventListener("click", this.preventClick);
          this.goToIndex({
            i: this.iconRefs.indexOf(element),
            custEase: "linear",
            dur: 100,
          });
        }
      });
    }
    ////*/

    //* Add mousedown event to timelineCont div
    this.$refs.timelineCont.addEventListener("mousedown", (e) => {
      this.tlPos = e.pageX;
      this.draggingBar = 1;
    });

    //* add mouseup event to window, blanket end dragging function
    window.addEventListener("mouseup", () => {
      this.endDragScroll();
      this.draggingBar = 0;
      this.iconRefs.forEach((element) => {
        element.removeEventListener("mousemove", this.toggleDraggingBar);
      });
    });

    //* add mouesup event to kick off timeline animation
    // window.addEventListener("mouseup", () => {
    document.getElementById("app").addEventListener("mouseup", (e) => {
      if (!this.timeline && !this.loading) {
        this.toTimeline();
        this.removeListeners(e.target);
      }
    });

    //* mousemove event that drives scrolling icons
    window.addEventListener("mousemove", (e) => {
      this.prevPos = this.tlPos;
      this.tlPos = e.pageX;
      // * report mouse position for timeline drag
      if (this.draggingBar) this.dragSlideIcons(e);
    });

    //* Blanket window mousedown event to get mouse position
    window.addEventListener("mousedown", (e) => {
      this.tlPos = e.pageX;
    });

    //* Quick run the goToIndex on window resize
    window.addEventListener("resize", () => {
      this.setLogoPos();
      // this.getIconPos();
      // const doit = setTimeout(function () {
      if (this.timeline) {
        this.$nextTick(() => {
          this.goToIndex({ i: this.currentIndex, custEase: "linear", dur: 0 });
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
$inactiveFill: rgb(165, 165, 175);
$activeFill: rgb(42, 42, 53);
:root {
  --iconmargin: 0px;
}
body {
  overflow: scroll !important;
}
#jaLogo {
  display: none;
  position: fixed;
  // height: 60px;
  top: 8px;
  left: 50px;
  z-index: 1000;
  // visibility: hidden;
  opacity: 0;
  image-rendering:optimizeQuality;
  &:hover {
    cursor: pointer;
  }
}
#JAname {
  // position: fixed;
  opacity: 0;
  margin-top: 50px;
  width: 535px;
}
#middleTick {
  position: fixed;
  left: 50vw;
  height: 20px;
  bottom: 0px;
  border: solid rgb(85, 83, 83) 1px;
  width: 0px;
  margin: auto;
  opacity: 0;
}
#welcome {
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: 500;
}
#timelineCont {
  // border-top: solid 2px rgb(42, 42, 53);
  // background-color: rgb(129, 135, 141);
  position: fixed;
  display: block;
  // visibility: hidden;
  opacity: 0;
  width: 100vw;
  bottom: 0;
  /* z-index: -1; */
}
.icons {
  opacity: 0;
  // height: 30px;
  display: grid;
  grid-template-areas:
    "icon"
    "date";
  grid-template-rows: 33px 1px;
  height: 42px;
  position: fixed;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.iconWrapper {
  margin: var(--iconmargin);
  grid-area: icon;
  // margin: auto;
  // vertical-align: bottom;
}
.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}
.dates {
  grid-area: date;
  position: relative;
  font-size: 0.4em;
  display: block;
  opacity: 0;
  /* visibility: hidden; */
  left: 0px;
  top: -4px;
  z-index: 0;
}
.iconSVG {
  pointer-events: none;
}
// Scale icons taht are not part of the logo
#broadway_market, #crowl_cabin, #shoreline_library, #bandon_bluff, #quiet_cove, #ray_mary{
  transform: scale(0.01);
}
</style>
